import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContentHolder = styled.div`
  .franchisePic {
    float: right;
    width: 200px;
    height: 300px;
  }
`

const Content = styled.div``

export default ({ data }) => {
  const franchisePic = data.franchisePic

  return (
    <Layout>
      <SEO title="Franchise" />
      <ContentHolder>
        <Img
          className="franchisePic"
          fluid={franchisePic.childImageSharp.fluid}
        />
        <Content>
          <p>Franchising opportunities are now available.</p>
          <p>Please call 817-870-2571 for more information.</p>
          <p>Franchise Fee: $40,000</p>
          <p>Total franchise costs could range from $200,000 to $600,000.</p>
          <p>
            “I don’t want to oversee more company stores, but would rather
            enlarge the scope of Mama’s through well-trained, well-run franchise
            operations. I think we can better expand through franchising.”
          </p>
          <p>— Jordan Scott, Mama’s President</p>
        </Content>
      </ContentHolder>
    </Layout>
  )
}

export const query = graphql`
  query FranchiseQuery {
    franchisePic: file(relativePath: { eq: "jsPic.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
